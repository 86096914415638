import React from 'react';
import '../../assets/css/style.css';
import '../../assets/css/custom.css';
import '../../assets/css/responsive.css';
import '../../assets/css/setting.css';
import '../../assets/css/discount.css';
import '../../assets/css/integrate.css';
import '../../assets/css/sdk.css';
import '../../assets/css/home_page.css';

// import '@nosferatu500/react-sortable-tree/style.css';

import baseHelper from '../../helpers/BaseHelper';

import SideBar from './Sidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { map } from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';

import storeActions from '../../redux/futures/store/actions';

import { connect } from 'react-redux';
import routes from '../../routes';

import ModalIntroduceVietQr from '../../components/ModalIntroduceVietQr';

import queryString from 'query-string';

import appConfig from '../../config/app';

class AppLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowModalIntroduceVietQr: false,
        };
    }

    componentDidMount() {
        // let total_show = Number(baseHelper.getCookie("total_message_impressions"));
        // let total_show_new = 0;
        // let date_show_message = baseHelper.getCookie("date_show_message");
        // let date = baseHelper.formatDateToStr(new Date(), appConfig.DEFAULT_FORMAT_DATE);
        // let isReloadCookie = baseHelper.getCookie("is_reload_cookie");
        // let is_not_show_message = baseHelper.getCookie("not_show_message");
        // if (isReloadCookie == 'false') {
        //   if (!is_not_show_message) {
        //     if (date_show_message && total_show) {
        //       if (total_show <= 3 && date_show_message != date) {
        //         total_show_new = total_show + 1;
        //         baseHelper.setCookie("total_message_impressions", total_show_new)
        //         baseHelper.setCookie("date_show_message", date);
        //         this.setState({
        //           isShowModalIntroduceVietQr: true
        //         })
        //       }
        //     } else {
        //       baseHelper.setCookie("total_message_impressions", 1);
        //       baseHelper.setCookie("date_show_message", baseHelper.formatDateToStr(new Date(), appConfig.DEFAULT_FORMAT_DATE));
        //       this.setState({
        //         isShowModalIntroduceVietQr: true
        //       })
        //     }
        //   }
        // }

        let userInfo = this.props.store.userInfo || {};
        let listEmailWhitelist = ['marketing@hion.com.vn', 'hotroladipage.com@gmail.com', 'nanapham0412@gmail.com', 'ketoan@ladipage.vn'];
        const notRedirects=  [
            '/checkin/info-handle',
            '/check-qrcode',
            '/checkin'
        ];
        let isNotRedirect = false;
        for(let i =0;i< notRedirects.length;i++) {
            if (window.location.href.indexOf(notRedirects[i]) >= 0) {
                isNotRedirect = true;
                break;
            }
        }

        // if (userInfo && (!userInfo.is_use_embed || userInfo.is_use_embed === false) &&  !isNotRedirect && !listEmailWhitelist.includes(userInfo.email)) {
        //     window.location.href = "https://builder.ladipage.com/e-commerce";
        // }
        if (userInfo && userInfo.email && !listEmailWhitelist.includes(userInfo.email)  &&  !isNotRedirect) {
            window.location.href = "https://builder.ladipage.com/e-commerce";
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    onOkNotification = () => {
        baseHelper.setCookie('NOTIFICATION_CREDIT', true, 86400);
        window.LadiUI.closeModal('confirm-notification-credit');
        window.LadiChat.push([['do', 'chat:open']]);
    };

    openNotification = () => {
        setTimeout(() => {
            window.LadiUI.showModal('confirm-notification-credit');
        }, 2000);
    };

    render() {
        const { scopes, userInfo } = this.props.store;
        let url = window.location.href;

        let isPageCheckIn = url.includes('/checkin');
        let isPageCheckQrCode = url.includes('/check-qrcode');

        let isDomainIPay = url.includes('app.ipay.com.vn');

        if (isDomainIPay) {
            document.title = 'iPay.com.vn - Nền tảng quản lý bán hàng thương mại điện tử';
        }

        const availableRoutes = routes.getRoutesByScopes(scopes, userInfo.is_show_course);
        // const notificationCredit = baseHelper.getCookie('NOTIFICATION_CREDIT');
        // if (!notificationCredit && store && (store.app_credit <= 30)) {
        //     if (window.LadiUI) {
        //         this.openNotification();
        //     }
        // }

        const pageCheckIn = queryString.parse(this.props.location.search);
        return (
            <section className='ladiui wrapper' style={isPageCheckQrCode || isPageCheckIn ? { minWidth: 'auto' } : {}}>
                {!isPageCheckQrCode && !isPageCheckIn && <SideBar scopes={scopes} userInfo={userInfo} isDomainIPay={isDomainIPay} />}
                <div
                    className='ladiui main-custom'
                    id='elm-main'
                    style={isPageCheckQrCode || isPageCheckIn ? { marginLeft: 0, width: '100%' } : {}}
                >
                    {!isPageCheckQrCode && !isPageCheckIn && <Topbar availableRoutes={availableRoutes} />}
                    <div className='ladiui content-main-full' style={isPageCheckQrCode || isPageCheckIn ? { padding: 0 } : {}}>
                        <Switch>
                            {map(availableRoutes, (route, index, key) => {
                                return <Route key={index} path={route.path} exact={route.exact} component={route.component} />;
                            })}
                            <Route render={() => <Redirect to='/orders' />} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
                <ModalIntroduceVietQr
                    onClose={() => {
                        this.setState({
                            isShowModalIntroduceVietQr: !this.state.isShowModalIntroduceVietQr,
                        });
                    }}
                    isVietQrModal={true}
                    isShow={this.state.isShowModalIntroduceVietQr}
                />
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (storeID) => dispatch(storeActions.getUserInfo(storeID)),
    };
};

const mapStateToProps = (state) => ({
    store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
