import React from 'react';

import storeActions from '../redux/futures/store/actions';
import * as storeTypes from '../redux/futures/store/types';

import authActions from '../redux/futures/auth/actions';

import { connect } from 'react-redux';
import AppLayout from './apps/AppLayout';
import CheckoutLayout from './CheckoutLayout';
import LoadingScene from '../components/LoadingScene';
import appConfig from '../config/app';
import baseHelper from '../helpers/BaseHelper';
import * as commonType from '../redux/futures/common/types';

import { Route, Switch } from 'react-router-dom';
import moment from 'moment';

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        this.props.getUserInfo();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }
        if (this.props.storeReducer.action != nextProps.storeReducer.action) {
            if (nextProps.storeReducer.action === storeTypes.GET_USER_INFO) {
                if (nextProps.storeReducer.status) {
                    if (!nextProps.storeReducer.noStore) {
                        const ladiUID = nextProps.storeReducer.userInfo.ladi_uid;

                        if (nextProps.storeReducer.userInfo.currentStore) {
                            // if (nextProps.storeReducer.userInfo.store) {
                            const token = baseHelper.getCookie(appConfig.COOKIE.SSID);
                            if (!window.LadiChat) {
                                window.LadiChat = [];
                            }

                            const name = nextProps.storeReducer.userInfo.fullname;
                            const phone = nextProps.storeReducer.userInfo.phone;
                            const email = nextProps.storeReducer.userInfo.email;
                            const avatar = nextProps.storeReducer.userInfo.avatar;
                            const pkgName = nextProps.storeReducer.userInfo.currentStore.pkg_name;
                            const type = pkgName != appConfig.PACKAGE_NAME.STARTER ? 'USER' : 'LEAD';
                            const exp_date = moment(new Date(nextProps.storeReducer.userInfo.currentStore.pkg_exp)).format(
                                appConfig.DB_DATE_TIME_FORMAT
                            );
                            const is_expired = moment(new Date(nextProps.storeReducer.userInfo.currentStore.pkg_exp)).diff(new Date()) < 0;

                            window.LadiChat.push([
                                ['set', 'user:name', name],
                                ['set', 'user:email', email],
                                ['set', 'user:phone', phone],
                                ['set', 'user:avatar', avatar],
                                ['set', 'user:type', type],
                                ['set', 'user:token', token],
                                ['set', 'custom_field:pkg_name_ladisales', pkgName],
                                ['set', 'custom_field:exp_date_ladisale', exp_date],
                                ['set', 'custom_field:is_expired', is_expired],
                            ]);
                        }

                        if (nextProps.storeReducer.userInfo.currentStore) {
                            const storeID = nextProps.storeReducer.userInfo.currentStore.store_id;
                            this.props.signInSuccess(ladiUID, storeID);
                        }
                    }

                    this.setState({
                        loaded: true,
                    });

                    if (nextProps.storeReducer.userInfo.stores.length <= 0) {
                        setTimeout(() => {
                            window.location.href = 'https://www.ladisales.vn/';
                        }, 10000);
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.storeReducer.message, 'OK');
                }
            }
            if (nextProps.storeReducer.action === commonType.ASYNC_END) {
                this.setState({
                    loaded: true,
                });
            }
        }
    }

    render() {
        if (!this.state.loaded) {
            return <LoadingScene />;
        }

        const stores = this.props.storeReducer.userInfo.stores || [];

        if (stores.length <= 0) {
            return (
                <div style={{ marginTop: '15%' }}>
                    <div
                        style={{
                            width: 290,
                            margin: '0 auto',
                            border: 0,
                            float: 'none',
                        }}
                    >
                        <a href='https://www.ladisales.vn/'>
                            <img src={appConfig.COLOR_LOGO} alt='ladisales.vn'></img>
                        </a>
                    </div>
                    <p style={{ width: '100%', textAlign: 'center', fontSize: '16px', lineHeight: 1.6, padding: '30px', color: '#000' }}>
                        {/* <span style={{ fontWeight: 'bold' }}>Thông báo!<br></br></span> */}
                        LadiSales chỉ áp dụng cho gói Pro LadiPage trở lên. Vui lòng liên hệ Hỗ trợ để biết thêm chi tiết.<br></br>Xin cám
                        ơn!<br></br>
                    </p>
                </div>
            );
        }

        return (
            <Switch>
                <Route path='/(checkout)' render={({ location }) => <CheckoutLayout />} />
                <Route component={AppLayout} />
            </Switch>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: () => dispatch(storeActions.getUserInfo()),
        signInSuccess: (ladiUID, storeID) => dispatch(authActions.signInSuccess(ladiUID, storeID)),
    };
};

const mapStateToProps = (state) => ({
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
